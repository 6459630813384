<template>
    <section class="info-1">
        <div class="container py-5">
            <h1 class="display-4 font-weight-bold text-center mb-5 title"
                data-aos="fade-up"
                data-aos-duration="1000">
                年齡 <br>
                <span class="font-weight-normal">VS</span> <br>
                體內玻尿酸HA含量
            </h1>
            <div class="position-relative">
                <div class="position-absolute background-chart-wrapper"
                     data-aos="fade-up"
                     data-aos-duration="750">
                    <chartist class="background-chart"
                              ratio="ct-major-second"
                              type="Line"
                              v-bind:data="bgChartData"
                              v-bind:options="bgChartOptions"
                              v-bind:event-handlers="bgChartEventHandlers">
                    </chartist>
                    <div class="position-absolute background-filter"></div>
                </div>

                <dl class="row"
                    data-aos="fade"
                    data-aos-duration="1000">
                    <dt class="col-6 text-right mb-2">
                        <h3>20歲</h3>
                    </dt>
                    <dd class="col-6 text-left mb-2">
                        <h3>100%</h3>
                    </dd>

                    <dt class="col-6 text-right mb-2">
                        <h3>30歲</h3>
                    </dt>
                    <dd class="col-6 text-left mb-2">
                        <h3>65%</h3>
                    </dd>

                    <dt class="col-6 text-right mb-2">
                        <h3>50歲</h3>
                    </dt>
                    <dd class="col-6 text-left mb-2">
                        <h3>45%</h3>
                    </dd>

                    <dt class="col-6 text-right mb-2">
                        <h3>60歲</h3>
                    </dt>
                    <dd class="col-6 text-left mb-2">
                        <h3>25%</h3>
                    </dd>
                </dl>

                <p class="text-right"
                   data-aos="fade"
                   data-aos-duration="1000">
                    * 資料來源：營養新知
                </p>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "HaB5AquaSpaInfo1",
        data () {
            return {
                seq: 0,
                delays: 100,
                durations: 1000,

                bgChartData: {
                    "labels": ["20歲", "30歲", "50歲", "60歲"],
                    "series":[
                        [100, 65, 45, 25],
                    ]
                },
                bgChartOptions: {
                    "fullWidth": true,

                },
                bgChartEventHandlers: [
                    {
                        event: "draw",
                        fn: (data) => {
                            this.seq ++;
                            switch (data.type) {
                                case "line": {
                                    data.element.animate({
                                        opacity: {
                                            // The delay when we like to start the animation
                                            begin: this.seq * this.delays + 1000,
                                            // Duration of the animation
                                            dur: this.durations,
                                            // The value where the animation should start
                                            from: 0,
                                            // The value where it should end
                                            to: 1
                                        }
                                    });
                                    break;
                                }

                                case "label": {
                                    if (data.axis === "x") {
                                        data.element.animate({
                                            y: {
                                                begin: this.seq * this.delays,
                                                dur: this.durations,
                                                from: data.y + 100,
                                                to: data.y,
                                                // We can specify an easing function from Chartist.Svg.Easing
                                                easing: "easeOutQuart"
                                            }
                                        });
                                    }

                                    if (data.axis === "y") {
                                        data.element.animate({
                                            x: {
                                                begin: this.seq * this.delays,
                                                dur: this.durations,
                                                from: data.x - 100,
                                                to: data.x,
                                                easing: "easeOutQuart"
                                            }
                                        });
                                    }
                                    break;
                                }

                                case "point": {
                                    data.element.animate({
                                        x1: {
                                            begin: this.seq * this.delays,
                                            dur: this.durations,
                                            from: data.x - 10,
                                            to: data.x,
                                            easing: "easeOutQuart"
                                        },
                                        x2: {
                                            begin: this.seq * this.delays,
                                            dur: this.durations,
                                            from: data.x - 10,
                                            to: data.x,
                                            easing: "easeOutQuart"
                                        },
                                        opacity: {
                                            begin: this.seq * this.delays,
                                            dur: this.durations,
                                            from: 0,
                                            to: 1,
                                            easing: "easeOutQuart"
                                        }
                                    });
                                    break;
                                }

                                case "grid": {
                                    // Using data.axis we get x or y which we can use to construct our animation definition objects
                                    let pos1Animation = {
                                        begin: this.seq * this.delays,
                                        dur: this.durations,
                                        from: data[data.axis.units.pos + "1"] - 30,
                                        to: data[data.axis.units.pos + "1"],
                                        easing: "easeOutQuart"
                                    };

                                    let pos2Animation = {
                                        begin: this.seq * this.delays,
                                        dur: this.durations,
                                        from: data[data.axis.units.pos + "2"] - 100,
                                        to: data[data.axis.units.pos + "2"],
                                        easing: "easeOutQuart"
                                    };

                                    let animations = {};
                                    animations[data.axis.units.pos + "1"] = pos1Animation;
                                    animations[data.axis.units.pos + "2"] = pos2Animation;
                                    animations["opacity"] = {
                                        begin: this.seq * this.delays,
                                        dur: this.durations,
                                        from: 0,
                                        to: 1,
                                        easing: "easeOutQuart"
                                    };

                                    data.element.animate(animations);
                                    break;
                                }
                            }
                        }
                    },
                ],
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "./variables.scss";

    .info-1 {
        background-color: $background-color-white;

        .title {
            color: $text-color-title;
        }

        dl {
            color: $text-color-dark;
        }

        .background-chart-wrapper {
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            & > .background-chart {
                max-height: 100%;
            }

            & > .background-filter {
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;

                background-color: transparent;
            }
        }
    }
</style>

<style lang="scss">
    @import "./variables.scss";

    .info-1 {
        .background-chart-wrapper {
            & > .background-chart {
                svg {
                    .ct-series {
                        &.ct-series-a {
                            & > .ct-line {
                                stroke: $text-color-title;
                                fill: none;
                                stroke-width: 4px;
                                stroke-dasharray: 5px;
                                animation: dashoffset 1s linear infinite;
                            }

                            & > .ct-point {
                                stroke: $text-color-title;
                                stroke-width: 10px;
                                animation: exploding-stroke 1s ease-out infinite;
                            }
                        }
                    }
                }
            }
        }
    }

    @keyframes dashoffset {
        0% {
            stroke-dashoffset: 0;
        }

        100% {
            stroke-dashoffset: -20px;
        }
    }

    @keyframes exploding-stroke {
        0% {
            stroke-width: 2px;
            opacity: 1;
        }
        100% {
            stroke-width: 20px;
            opacity: 0;
        }
    }
</style>
